import { getSession } from "next-auth/client";

import UsersApiClient from "src/apiClient/users";
import { getSessionToken, getUserId } from "src/common/authUtil";
import SignIn from "@components/SignIn";
import User from "src/proxyClient/users";
import QuizRedirect from "mixins/quizRedirect";
import queryObjectToString from "mixins/queryObjectToString";
import { sha256 } from 'js-sha256';

export async function getServerSideProps({ req, res, query }) {
  const HANDLED_ROUTE = await handleRedirect(req, res, query);
  if (HANDLED_ROUTE) {
    return {
      redirect: {
        destination: HANDLED_ROUTE,
        permanent: false,
      },
    };
  }
  return {
    props: {}, // will be passed to the page component as props
  };
}

const checkProfileIncomplete = (userProfileData) => {
  return (
    !userProfileData.hasOwnProperty("cpf") ||
    userProfileData.cpf === null ||
    userProfileData.cpf === undefined
  );
};

const handleRedirect = async (req, res, query) => {
  const offerProvider = "itau";
  const completedQuery = queryObjectToString(query);
  const queryOfferUrl = query.offerUrl;
  const queryCallback = query.callback;
  const session = await getSession({ req });
  const sessionToken = getSessionToken(req);
  const id = getUserId(session);

  const userProfileData = await UsersApiClient.getUserProfileInfo(
    id,
    sessionToken
  );

  if (!session) {
    return false;
  }

  const isProfileIncomplete = await checkProfileIncomplete(userProfileData);

  if (isProfileIncomplete) {
    return `/completar-perfil?${completedQuery}`;
  } else if (queryOfferUrl) {
    const actualDate = new Date()
    const nameArray = userProfileData.name.split(' ')
    const lastNameIndex = nameArray.length - 1
    const leadRetargetingPayload = {
      contact: {
        email: userProfileData.email,
        phone: userProfileData.cellphone,
        hashedEmail: sha256(userProfileData.email),
        firstName: nameArray[0],
        lastName: nameArray[lastNameIndex],
        audienceName: 'plusdin_membros_offers',
        link: queryOfferUrl
      },
      listId: 'plusdin_membros_offers',
      startDate: actualDate
    }
    const response = await User.sendLeadRetargeting(leadRetargetingPayload)
    console.log('response', response)

    return `/home?offerUrl=${queryOfferUrl}`;
  } else if (QuizRedirect.isQuizRedirect(query)) {
    return `${QuizRedirect.urlToRedirect(query)}?${completedQuery}`;
  } else if (session && queryCallback === "form-itau") {
    const response = await User.checkUserPreApproval(
      offerProvider,
      userProfileData.cpf
    );
    const sToken = Buffer.from(sessionToken).toString("base64");
    const sId = Buffer.from(id).toString("base64");

    return response.codigoStatusProposta === 2
      ? `${process.env.NEXT_PUBLIC_PROPOSALS_URL}/${offerProvider}/cc/new?proposals-request=${sToken}&proposals-member=${sId}`
      : `/nao-foi-dessa-vez${completedQuery}`;
  } else {
    return `/home?${completedQuery}`;
  }
};

function Login() {
  return <SignIn />;
}

export default Login;
